import React from "react";
import { useTranslation } from "react-i18next";

const Breadcrumbs = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="slider bg-navy-blue bg-scroll pos-rel breadcrumbs-page">
      <div className="container">
        {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">
                  <i className="icofont-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {props.title}
              </li>
            </ol>
          </nav> */}

        <h1 style={{ textAlign: i18n.dir() === "rtl" ? "right" : "left" }}>
          {t(props.title)}
        </h1>
        <div
          className="breadcrumbs-description"
          style={{
            ...(i18n.dir() === "ltr"
              ? {
                  borderLeft: "2px solid #E91B34",
                  borderRight: "none",
                  textAlign: "left",
                }
              : {
                  borderRight: "2px solid #E91B34",
                  borderLeft: "none",
                  textAlign: "right",
                }),
          }}
        >
          {t(props.description)}
        </div>
      </div>
    </div>
  );
};
export default Breadcrumbs;
