import React from "react";
import { useTranslation } from "react-i18next";

const Content = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100 why-choose align-item-center justify-content-center">
          <div className="container align-item-center justify-content-center">
            <div className="row align-items-center pt-5">
              <div
                className="col-md-6 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h2
                  className="mb-4 fw-7 txt-blue"
                  style={{ textAlign: i18n.dir() === "ltr" ? "left" : "right" }}
                >
                  {t("about")}{" "}
                  <span className="fw-6 txt-orange">{t("linxArabia")}</span>
                </h2>
                <p
                  style={{ textAlign: i18n.dir() === "ltr" ? "left" : "right" }}
                >
                  {t("aboutDesc")}
                </p>
              </div>
              <div
                className="col-md-6 wow fadeInRight"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <img src="images/map-bg-orange.jpg" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white wide-tb-100 bg-wave">
          <div className="container pos-rel">
            <div className="row row justify-content-center">
              <div className="col-sm-12">
                <h1
                  className="heading-main wow fadeInDown"
                  data-wow-duration="0"
                  data-wow-delay="0s"
                >
                  <span>{t("ourGoodness")}</span>
                  {t("whatMakesUsSpecial")}
                </h1>
              </div>
              <div
                className="col-md-12 ml-auto why-choose wow fadeInRight"
                data-wow-duration="0"
                data-wow-delay="0.6s"
              >
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-id"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("reliability")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("reliabilityDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-history"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("quality")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("qualityDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 spacer-60 d-none d-md-none d-lg-block d-sm-none"></div>
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-id"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("visibility")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("visibilityDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-live-support"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("consultingServices")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("consultingServicesDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 text-center">
                  <div className="spacer-50"></div>
                  <a
                    href="https://booking.linxarabia.com/#/page/register"
                    target="_blank"
                    className="btn-theme bg-navy-blue"
                  >
                    {t("requestQuote")}{" "}
                    <i
                      className={`icofont-rounded-${
                        i18n.language === "en" ? "right" : "left"
                      }`}
                    ></i>
                  </a>
                  <div className="spacer-30"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wide-tb-60 p-0 bg-light-gray">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 wow pulse animated"
                data-wow-duration="0"
                data-wow-delay="0.7s"
              >
                <div className="bg-fixed pos-rel video-popup">
                  <div className="bg-overlay black opacity-50"></div>
                  <div className="zindex-fixed pos-rel">
                    <a href="#" className="play-video">
                      <i className="icofont-play icofont-4x"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="wide-tb-100 mb-spacer-md">
          <div className="container wide-tb-100 pb-0">
            <div className="row d-flex align-items-center">
              <div
                className="col col-12 col-lg-3 col-sm-6 wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-google-map"></i>
                  </p>
                  <span className="counter">15</span>
                  <div>Our Locations</div>
                </div>
              </div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.3s"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-globe"></i>
                  </p>
                  <span className="counter">110</span>
                  <span>+</span>
                  <div>Clients Worldwide</div>
                </div>
              </div>
              <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.6s"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-vehicle-delivery-van"></i>
                  </p>
                  <span className="counter">240</span>
                  <span>+</span>
                  <div>Owned Vehicles</div>
                </div>
              </div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.9s"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-umbrella-alt"></i>
                  </p>
                  <span className="counter">2340</span>
                  <div>Tonnes Transported</div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="wide-tb-100 mb-spacer-md">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <div className="counter-style-1 light-bg" dir="ltr">
                  <p className="mb-1">
                    <i className="icofont-google-map"></i>
                  </p>
                  <span className="counter">15</span>
                  <div>{t("ourLocations")}</div>
                </div>
              </div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0.3s"
              >
                <div className="counter-style-1 light-bg" dir="ltr">
                  <p className="mb-1">
                    <i className="icofont-globe"></i>
                  </p>
                  <span className="counter">110</span>
                  <span>+</span>
                  <div>{t("clientsWorldWide")}</div>
                </div>
              </div>
              <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0.6s"
                dir="ltr"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-vehicle-delivery-van"></i>
                  </p>
                  <span className="counter">240</span>
                  <span>+</span>
                  <div>{t("ownedVehicles")}</div>
                </div>
              </div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0.9s"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-umbrella-alt"></i>
                  </p>
                  <span className="counter">2340</span>
                  <div>{t("tonnesTransported")}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pos-rel bg-sky-blue">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-5 col-12 p-0">
                <img src="images/why-choose-us.jpg" className="w-100" alt="" />
              </div>
              <div className="col-lg-6 col-12">
                <div className="p-5 about-whoose">
                  <h1 className="heading-main text-left mb-4">
                    <span>{t("whyChoose")}</span>
                    {t("linxArabia")}
                  </h1>
                  <ul className="list-unstyled icons-listing theme-orange w-half mb-0">
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0s"
                    >
                      <i className="icofont-check"></i>
                      {t("deliverEnvironmentallyResponsibleClientServices")}
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0.1s"
                    >
                      <i className="icofont-check"></i>
                      {t("beAnActiveCommunityPartner")}
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0.2s"
                    >
                      <i className="icofont-check"></i>
                      {t("driveContinousImprovement")}
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0.3s"
                    >
                      <i className="icofont-check"></i>
                      {t("clearanceAndComplianceService")}
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0.4s"
                    >
                      <i className="icofont-check"></i>
                      {t("clearanceAndComplianceService")}
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0.5s"
                    >
                      <i className="icofont-check"></i>
                      {t("maintainHighEthicalStandards")}
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0.6s"
                    >
                      <i className="icofont-check"></i>
                      {t("airAndOceanCargoInsurance")}
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="0"
                      data-wow-delay="0.7s"
                    >
                      <i className="icofont-check"></i>
                      {t("weEnsureCompleteSecurity")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wide-tb-100 pb-0 team-section-bottom pos-rel">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="heading-main">
                <span>Face Behind Logzee</span>
                Our Team
              </h1>
            </div>
            <div className="row pb-4">
              <div
                className="col-sm-12 col-md-4 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <div className="team-section-two">
                  <img
                    src="images/team/team-1.jpg"
                    alt=""
                    className="rounded"
                  />
                  <h4 className="h4-md txt-orange">John Morise</h4>
                  <h5 className="h5-md txt-ligt-gray">Founder</h5>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-4 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="team-section-two">
                  <img
                    src="images/team/team-2.jpg"
                    alt=""
                    className="rounded"
                  />
                  <h4 className="h4-md txt-orange">Kevin Mash</h4>
                  <h5 className="h5-md txt-ligt-gray">Head Operational</h5>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-4 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <div className="team-section-two">
                  <img
                    src="images/team/team-3.jpg"
                    alt=""
                    className="rounded"
                  />
                  <h4 className="h4-md txt-orange">Mike Douglos</h4>
                  <h5 className="h5-md txt-ligt-gray">Team Lead Support</h5>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="bg-white wide-tb-100 mb-spacer-md">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="heading-main">
                <span>Request a </span>
                Free Quote
              </h1>
            </div>
            <div className="row">
              <div
                className="col-lg-4 wow fadeInRight"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <div className="align-self-stretch h-100 align-items-center d-flex bg-with-text">
                  Whether you require distribution or fulfillment, defined
                  freight forwarding, or a complete supply chain solution, we
                  are here for you.
                </div>
              </div>
              <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
              <div
                className="col-lg-8 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <form
                  action="#"
                  method="post"
                  noValidate="novalidate"
                  className="rounded-field gray-field"
                >
                  <div className="form-row mb-4">
                    <div className="col">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="col">
                      <select
                        title="Please choose a package"
                        required=""
                        name="package"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="">Transport Type</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                        <option value="Type 3">Type 3</option>
                        <option value="Type 4">Type 4</option>
                      </select>
                    </div>
                    <div className="col">
                      <select
                        title="Please choose a package"
                        required=""
                        name="package"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="">Type of freight</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                        <option value="Type 3">Type 3</option>
                        <option value="Type 4">Type 4</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="col">
                      <textarea
                        rows="7"
                        placeholder="Message"
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-row text-center">
                    <button
                      type="submit"
                      className="form-btn mx-auto btn-theme bg-orange"
                    >
                      Request Quote <i className="icofont-rounded-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}

        <section className="wide-tb-100 bg-white pos-rel" dir="ltr">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>{t("someOfOur")}</span>
                  {t("clients")}
                </h1>
              </div>
              <div
                className="col-sm-12 wow slideInDown"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div
                  className="owl-carousel owl-theme align-items-center justify-content-center"
                  id="home-clients"
                >
                
                  <div className="item">
                    <img src="images/clients/client4.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client5.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client6.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client7.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client8.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client9.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client10.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client11.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client12.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wide-tb-100 faqs">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">
                  <span>Frequently Asked</span>
                  Questions
                </h1>
              </div>
              <div
                className="col-sm-12 col-md-6 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h4 className="h4-md mb-3">
                  Lobortis sit magna ornare magna egestas?
                </h4>
                <p>
                  Etiam sit amet mauris suscipit sit amet in odio. Integer
                  congue leo metus. Vitae arcu mollis blandit ultrice ligula
                  egestas magna suscipit lectus magna suscipit luctus undo
                  blandit vitae purus laoreet
                </p>
              </div>
              <div
                className="col-sm-12 col-md-6 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <h4 className="h4-md mb-3">Aliquam dapibus pretium ornare?</h4>
                <p>
                  Feugiat eros ligula massa lipsum primis in orci luctus et
                  ultrices posuere cubilia curae congue lorem. ante ipsum primis
                  in faucibus bibendum sit amet in odio
                </p>
              </div>
              <div
                className="col-sm-12 col-md-6 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.4s"
              >
                <h4 className="h4-md mb-3">
                  Placeat axime facere omnis volute?
                </h4>
                <p>
                  Etiam sit amet mauris suscipit sit amet in odio. Integer
                  congue leo metus. Vitae arcu mollis blandit ultrice ligula
                  egestas magna suscipit lectus magna suscipit luctus undo
                  blandit vitae purus laoreet
                </p>
              </div>
              <div
                className="col-sm-12 col-md-6 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.6s"
              >
                <h4 className="h4-md mb-3">Dapibus lobortis pretium ornare?</h4>
                <p>
                  Feugiat eros ligula massa lipsum primis in orci luctus et
                  ultrices posuere cubilia curae congue lorem. ante ipsum primis
                  in faucibus bibendum sit amet in odio
                </p>
              </div>
              <div
                className="col-sm-12 col-md-6 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.8s"
              >
                <h4 className="h4-md mb-3">
                  An interdum lobortis pretium ornare?
                </h4>
                <p>
                  Etiam sit amet mauris suscipit sit amet in odio. Integer
                  congue leo metus. Vitae arcu mollis blandit ultrice ligula
                  egestas magna suscipit lectus magna suscipit luctus undo
                  blandit vitae purus laoreet
                </p>
              </div>
              <div
                className="col-sm-12 col-md-6 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.9s"
              >
                <h4 className="h4-md mb-3">
                  Interdum lobortis pretium ornare?
                </h4>
                <p>
                  Feugiat eros ligula massa lipsum primis in orci luctus et
                  ultrices posuere cubilia curae congue lorem. ante ipsum primis
                  in faucibus bibendum sit amet in odio
                </p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
          <div className="bg-overlay blue opacity-60"></div>
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-4 col-md-12 mb-0 wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <h4 className="h4-xl">Interested in working with Logzee?</h4>
              </div>
              <div
                className="col wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <div className="center-text">
                  We don’t just manage suppliers, we micro-manage them. We have
                  a consultative, personalized approach
                </div>
              </div>
              <div
                className="col-sm-auto wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.3s"
              >
                <a href="#" className="btn btn-theme bg-white bordered">
                  Get In Touch <i className="icofont-rounded-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </>
  );
};
export default Content;
