import React from "react";
import { useTranslation } from "react-i18next";

const Content = () => {
  const { t } = useTranslation();

  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100 bg-white pos-rel" dir="ltr">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>{t("someOfOur")}</span>
                  {t("clients")}
                </h1>
              </div>
              <div
                className="col-sm-12 wow slideInDown"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div
                  className="owl-carousel owl-theme align-items-center justify-content-center"
                  id="home-clients"
                >
                
                  <div className="item">
                    <img src="images/clients/client4.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client5.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client6.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client7.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client8.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client9.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client10.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client11.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client12.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
