import React from "react";
import { useTranslation } from "react-i18next";

const Content = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <main id="body-content" style={{ overflowY: "inherit" }}>
        <section className="bg-sky-blue wide-tb-100 pb-0">
          <div className="container pos-rel">
            <div className="row">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>{t("whatWeOffer")}</span>
                  {t("ourCoreServices")}
                </h1>
              </div>
              <div
                className="col-md-3 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <a href="">
                  <div className="icon-box-4 h-100">
                    <i className="icofont-airplane-alt"></i>
                    <h6 className="h4-md mb-3">{t("2PL3PL")}</h6>
                    <p>{t("2PL3PLDesc")}</p>
                  </div>
                </a>
              </div>
              <div
                className="col-md-3 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.4s"
              >
                <a href="">
                  <div className="icon-box-4 h-100">
                    <i className="icofont-fire-truck"></i>
                    <h6 className="h4-md mb-3"> {t("landAirOceanFreight")}</h6>
                    <p>{t("landAirOceanFreightDesc")}</p>
                  </div>
                </a>
              </div>
              <div
                className="col-md-3 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.6s"
              >
                <a href="">
                  <div className="icon-box-4 h-100">
                    <i className="icofont-ship"></i>
                    <h6 className="h4-md mb-3">{t("crossBorderClearance")}</h6>
                    <p>{t("crossBorderClearanceDesc")}</p>
                  </div>
                </a>
              </div>
              <div
                className="col-md-3 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.6s"
              >
                <a href="">
                  <div className="icon-box-4 h-100">
                    <i className="icofont-ship"></i>
                    <h6 className="h4-md mb-3">
                      {t("warehousingAndFulfillment")}
                    </h6>
                    <p>{t("warehousingAndFulfillmentDesc")}</p>
                  </div>
                </a>
              </div>
              <div className="text-center btn-center mx-auto">
                <a
                  target="_blank"
                  href="https://booking.linxarabia.com/#/page/register"
                  className="btn-theme bg-navy-blue"
                >
                  {t("requestQuote")}{" "}
                  <i
                    className={`icofont-rounded-${
                      i18n.language === "en" ? "right" : "left"
                    }`}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white wide-tb-100 bg-wave">
          <div className="container pos-rel">
            <div className="row row justify-content-center">
              <div className="col-sm-12">
                <h1
                  className="heading-main wow fadeInDown"
                  data-wow-duration="0"
                  data-wow-delay="0s"
                >
                  <span>{t("ourGoodness")}</span>
                  {t("whatMakesUsSpecial")}
                </h1>
              </div>
              <div
                className="col-md-12 ml-auto why-choose wow fadeInRight"
                data-wow-duration="0"
                data-wow-delay="0.6s"
              >
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-id"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("reliability")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("reliabilityDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-history"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("quality")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("qualityDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 spacer-60 d-none d-md-none d-lg-block d-sm-none"></div>
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-id"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("visibility")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("visibilityDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="icon-box-2">
                      <div className="media">
                        <div
                          className="service-icon"
                          style={{
                            ...(i18n.dir() === "left"
                              ? { marginRight: "20px" }
                              : { marginLeft: "20px" }),
                          }}
                        >
                          <i className="icofont-live-support"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4
                            className="h4-md"
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("consultingServices")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("consultingServicesDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 text-center">
                  <div className="spacer-50"></div>
                  <a
                    href="https://booking.linxarabia.com/#/page/register"
                    target="_blank"
                    className="btn-theme bg-navy-blue"
                  >
                    {t("requestQuote")}{" "}
                    <i
                      className={`icofont-rounded-${
                        i18n.language === "en" ? "right" : "left"
                      }`}
                    ></i>
                  </a>
                  <div className="spacer-30"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wide-tb-100 pos-rel tracking parallax">
          <div className="bg-overlay blue"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h1
                  className="heading-main parallax-head wow fadeInDown"
                  data-wow-duration="0"
                  data-wow-delay="0"
                >
                  <span>{t("getUpdates")}</span>
                  {t("trackingYourFreight")}
                </h1>
                <form
                  className="form-inline wow fadeInUp"
                  data-wow-duration="0"
                  data-wow-delay="0.2"
                >
                  <input
                    type="text"
                    className="form-control mb-2 ml-sm-2 col"
                    placeholder={t("enterYourOrderNumber")}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      window.open(
                        "https://booking.linxarabia.com/#/page/login"
                      );
                    }}
                    className={`btn btn-theme bg-white mb-2 ${
                      i18n.dir() === "ltr" ? "ml-3" : "mr-3"
                    } bordered`}
                  >
                    {t("checkNow")}{" "}
                    <i
                      className={`icofont-rounded-${
                        i18n.language === "en" ? "right" : "left"
                      }`}
                    ></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="bg-sky-blue wide-tb-100 pb-0">
          <div className="container pos-rel">
            <div className="row justify-content-center">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>Face Behind LINX Arabia</span>
                  Our Team
                </h1>
              </div>
              <div className="row pb-4">
                <div
                  className="col-sm-12 col-md-2 wow fadeInUp"
                  data-wow-duration="0"
                  data-wow-delay="0s"
                >
                  <div className="team-section-two">
                    <h4 className="h4-md txt-orange">Fahim Chapra</h4>
                    <h5 className="h5-md txt-ligt-gray">General Manager</h5>
                  </div>
                </div>
                <div
                  className="col-sm-12 col-md-2 wow fadeInUp"
                  data-wow-duration="0"
                  data-wow-delay="0.1s"
                >
                  <div className="team-section-two">
                    <h4 className="h4-md txt-orange">Muaz Khanani</h4>
                    <h5 className="h5-md txt-ligt-gray">Manager</h5>
                  </div>
                </div>
                <div
                  className="col-sm-12 col-md-2 wow fadeInUp"
                  data-wow-duration="0"
                  data-wow-delay="0.2s"
                >
                  <div className="team-section-two">
                    <h4 className="h4-md txt-orange">Anwar</h4>
                    <h5 className="h5-md txt-ligt-gray">Team Lead Support</h5>
                  </div>
                </div>
                <div
                  className="col-sm-12 col-md-2 wow fadeInUp"
                  data-wow-duration="0"
                  data-wow-delay="0.2s"
                >
                  <div className="team-section-two">
                    <h4 className="h4-md txt-orange">Nouf</h4>
                    <h5 className="h5-md txt-ligt-gray">Team Lead Support</h5>
                  </div>
                </div>
                <div
                  className="col-sm-12 col-md-2 wow fadeInUp"
                  data-wow-duration="0"
                  data-wow-delay="0.2s"
                >
                  <div className="team-section-two">
                    <h4 className="h4-md txt-orange">Waseem Ahmed</h4>
                    <h5 className="h5-md txt-ligt-gray">Logistics Manager</h5>
                  </div>
                </div>
                <div
                  className="col-sm-12 col-md-2 wow fadeInUp"
                  data-wow-duration="0"
                  data-wow-delay="0.2s"
                >
                  <div className="team-section-two">
                    <h4 className="h4-md txt-orange">Waheed</h4>
                    <h5 className="h5-md txt-ligt-gray">
                      Freight Forwarding Manager
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="bg-white wide-tb-100 mb-spacer-md">
          <div className="container">
            <div className="col-sm-12">
              <h1 className="heading-main">
                <span>Request a </span>
                Free Quote
              </h1>
            </div>
            <div className="row">
              <div
                className="col-lg-4 px-10 text-center wow fadeInRight"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <div className="spacer-20 align-self-stretch h-100 align-items-center text-white d-flex bg-overlay blue">
                  Whether you require distribution or fulfillment, defined
                  freight forwarding, or a complete supply chain solution, we
                  are here for you.
                </div>
              </div>
              <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
              <div
                className="col-lg-8 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <form
                  action="#"
                  method="post"
                  noValidate="novalidate"
                  className="rounded-field gray-field"
                >
                  <div className="form-row mb-4">
                    <div className="col">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="col">
                      <select
                        title="Please choose a package"
                        required=""
                        name="package"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="">Transport Type</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                        <option value="Type 3">Type 3</option>
                        <option value="Type 4">Type 4</option>
                      </select>
                    </div>
                    <div className="col">
                      <select
                        title="Please choose a package"
                        required=""
                        name="package"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="">Type of freight</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                        <option value="Type 3">Type 3</option>
                        <option value="Type 4">Type 4</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="col">
                      <textarea
                        rows="7"
                        placeholder="Message"
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-row text-center">
                    <button
                      type="submit"
                      className="form-btn mx-auto btn-theme bg-orange"
                    >
                      Request Quote <i className="icofont-rounded-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}

        <section className="wide-tb-100 pb-0 mb-spacer-md">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <div className="counter-style-1 light-bg" dir="ltr">
                  <p className="mb-1">
                    <i className="icofont-google-map"></i>
                  </p>
                  <span className="counter">15</span>
                  <div>{t("ourLocations")}</div>
                </div>
              </div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0.3s"
              >
                <div className="counter-style-1 light-bg" dir="ltr">
                  <p className="mb-1">
                    <i className="icofont-globe"></i>
                  </p>
                  <span className="counter">110</span>
                  <span>+</span>
                  <div>{t("clientsWorldWide")}</div>
                </div>
              </div>
              <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0.6s"
                dir="ltr"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-vehicle-delivery-van"></i>
                  </p>
                  <span className="counter">240</span>
                  <span>+</span>
                  <div>{t("ownedVehicles")}</div>
                </div>
              </div>
              <div
                className="col col-12 col-lg-3 col-sm-6 wow pulse"
                data-wow-duration="0"
                data-wow-delay="0.9s"
              >
                <div className="counter-style-1 light-bg">
                  <p className="mb-1">
                    <i className="icofont-umbrella-alt"></i>
                  </p>
                  <span className="counter">2340</span>
                  <div>{t("tonnesTransported")}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wide-tb-100">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">
                  <span>{t("perfectionInTheseFields")}</span>
                  {t("ourCertifications")}
                </h1>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_1.jpg" alt="" className="rounded" />
                  {/* <div className="meta-box">
                    Saudi Food and Drug Authority (SFDA)<br></br> Certified
                  </div> 
                  <h4 className="h4-md mb-3">
                    <a>
                      {t("SFDA")} <br></br>
                      {t("certified")}
                    </a>
                  </h4>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_2.jpg" alt="" className="rounded" />
               
                  <h4 className="h4-md mb-3">
                    <a>{t("ISO9001")}</a>
                  </h4>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_3.jpg" alt="" className="rounded" />

                  <h4 className="h4-md mb-3">
                    <a>{t("ISO8001")}</a>
                  </h4>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_4.jpg" alt="" className="rounded" />

                  <h4 className="h4-md mb-3">
                    <a> {t("LEEDCertification")}</a>
                  </h4>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_5.jpg" alt="" className="rounded" />

                  <h4 className="h4-md mb-3">
                    <a>{t("TAPACCert")}</a>
                  </h4>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_6.jpg" alt="" className="rounded" />

                  <h4 className="h4-md mb-3">
                    <a>{t("SMETAIntertek")}</a>
                  </h4>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_7.jpg" alt="" className="rounded" />

                  <h4 className="h4-md mb-3">
                    <a>{t("saudiCivilDefenceLicense")}</a>
                  </h4>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 wow fadeInLeft"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div className="blog-warp">
                  <img src="images/blog_img_8.jpg" alt="" className="rounded" />

                  <h4 className="h4-md mb-3">
                    <a>{t("NFPAC")} </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
          <div className="bg-overlay blue opacity-60"></div>
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-4 col-md-12 mb-0 wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <h4 className="h4-xl">Interested in working with Logzee?</h4>
              </div>
              <div
                className="col wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.2s"
              >
                <div className="center-text">
                  We don’t just manage suppliers, we micro-manage them. We have
                  a consultative, personalized approach
                </div>
              </div>
              <div
                className="col-sm-auto wow slideInUp"
                data-wow-duration="0"
                data-wow-delay="0.3s"
              >
                <a href="#" className="btn btn-theme bg-white bordered">
                  Get In Touch <i className="icofont-rounded-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="twitter-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="twitter-feed d-flex align-items-center rounded-bottom">
                  <i className="icofont-twitter icon-feed txt-orange"></i>
                  <div className="tweet-stream"></div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="wide-tb-100 bg-white pos-rel" dir="ltr">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>{t("someOfOur")}</span>
                  {t("clients")}
                </h1>
              </div>
              <div
                className="col-sm-12 wow slideInDown"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div
                  className="owl-carousel owl-theme align-items-center justify-content-center"
                  id="home-clients"
                >
                
                  <div className="item">
                    <img src="images/clients/client4.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client5.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client6.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client7.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client8.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client9.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client10.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client11.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client12.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="wide-tb-100" dir="ltr">
          <div className="container">
            <div className="row d-flex">
              <div
                className="col-lg-6 col-sm-12 wow fadeInLeft justify-end"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <h4 className="h4-md mb-4 txt-orange">{t("findUsAtOffice")}</h4>
                <div className="contact-details standard row pos-rel d-inline-flex">
                  <div className="col">
                    <h4>{t("saudiArabia")}</h4>
                    <p>
                      <i className="icofont-phone"></i>{" "}
                      <span dir="ltr">+966 50 9651 493 | +966 12 4222026</span>
                    </p>
                    <div className="text-nowrap">
                      <i className="icofont-email"></i>{" "}
                      <a href="#">info@linxarabia.com</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <section className="map-bg map-shadow">
                  <div id="map-holder" className="pos-rel">
                    <div id="map_extended">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3711.752386774464!2d39.165472199999996!3d21.517416700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDMxJzAyLjciTiAzOcKwMDknNTUuNyJF!5e0!3m2!1sen!2s!4v1691756511537!5m2!1sen!2s"
                        width="100%"
                        height="100%"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
