import React from "react";

function Content() {
  return (
    <>
      <div style={{ padding: "20px", marginTop: "110px" }}>
        <h1>PRIVACY POLICY</h1>
        <h3 style={{ marginBlock: "10px" }}>1. Introduction</h3>
        Welcome to Linx Arabia Linx Arabia , we operates
        https://www.linxarabia.com/ (hereinafter referred to as “Service”). Our
        Privacy Policy governs your visit to https://www.linxarabia.com/, and
        explains how we collect, safeguard and disclose information that results
        from your use of our Service. We use your data to provide and improve
        Service. By using Service, you agree to the collection and use of
        information in accordance with this policy. Unless otherwise defined in
        this Privacy Policy, the terms used in this Privacy Policy have the same
        meanings as in our Terms and Conditions. Our Terms and Conditions
        (“Terms”) govern all use of our Service and together with the Privacy
        Policy constitutes your agreement with us (“agreement”).
        <h2>2. Definitions</h2>
        SERVICE means the https://www.linxarabia.com/ website operated by Linx
        Arabia PERSONAL DATA means data about a living individual who can be
        identified from those data (or from those and other information either
        in our possession or likely to come into our possession). USAGE DATA is
        data collected automatically either generated by the use of Service or
        from Service infrastructure itself (for example, the duration of a page
        visit). COOKIES are small files stored on your device (computer or
        mobile device). DATA CONTROLLER means a natural or legal person who
        (either alone or jointly or in common with other persons) determines the
        purposes for which and the manner in which any personal data are, or are
        to be, processed. For the purpose of this Privacy Policy, we are a Data
        Controller of your data. DATA PROCESSORS (OR SERVICE PROVIDERS) means
        any natural or legal person who processes the data on behalf of the Data
        Controller. We may use the services of various Service Providers in
        order to process your data more effectively. DATA SUBJECT is any living
        individual who is the subject of Personal Data. THE USER is the
        individual using our Service. The User corresponds to the Data Subject,
        who is the subject of Personal Data.
        <h3 style={{ marginBlock: "10px" }}>
          3. Information Collection and Use
        </h3>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
        <h3 style={{ marginBlock: "10px" }}>4. Types of Data Collected</h3>
        Personal Data While using our Service, we may ask you to provide us with
        certain personally identifiable information that can be used to contact
        or identify you (“Personal Data”). Personally identifiable information
        may include, but is not limited to: 0.1. Email address 0.2. First name
        and last name 0.3. Phone number 0.4. Address, State, Province,
        ZIP/Postal code, City 0.5. Cookies and Usage Data We may use your
        Personal Data to contact you with newsletters, marketing or promotional
        materials and other information that may be of interest to you. You may
        opt out of receiving any, or all, of these communications from us by
        following the unsubscribe link.
        <h4>Usage Data</h4>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access Service by or through a mobile
        device (“Usage Data”). This Usage Data may include information such as
        your computer's Internet Protocol address (e.g. IP address), browser
        type, browser version, the pages of our Service that you visit, the time
        and date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data. When you access Service with a
        mobile device, this Usage Data may include information such as the type
        of mobile device you use, your mobile device unique ID, the IP address
        of your mobile device, your mobile operating system, the type of mobile
        Internet browser you use, unique device identifiers and other diagnostic
        data.
        <h4>Location Data</h4>
        We may use and store information about your location if you give us
        permission to do so (“Location Data”). We use this data to provide
        features of our Service, to improve and customize our Service. You can
        enable or disable location services when you use our Service at any time
        by way of your device settings. Location Data - Linx Arabia To
        participate as a Linx Arabia driver, you must permit the Linx Arabia
        Services to access location services through the permission system used
        by your mobile operating system (“Platform”) or browser. We may collect
        the precise location of your device when the Linx Arabia app is running
        in the foreground or background of your device. We may also derive your
        approximate location from your IP address. We use your location
        information to verify that you are present in your preferred region or
        city when you begin or engage in a delivery through the Linx Arabia (a
        “Delivery”), connect you with delivery opportunities in your zone, and
        track the progress and completion of your Deliveries. You can enable the
        location tracking feature through the settings on your device or
        Platform or when prompted by the Linx Arabia mobile app. If you choose
        to disable the location feature through the settings on your device or
        Platform, Linx Arabia will not receive precise location information from
        your device, which will prevent you from being able to Trip and
        receiving delivery opportunities in your area.
        <h4>Tracking Cookies Data</h4>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information. Cookies are files with a
        small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your
        device. Other tracking technologies are also used such as beacons, tags
        and scripts to collect and track information and to improve and analyze
        our Service. You can instruct your browser to refuse all cookies or to
        indicate when a cookie is being sent. However, if you do not accept
        cookies, you may not be able to use some portions of our Service.
        Examples of Cookies we use: 4.1. Session Cookies: We use Session Cookies
        to operate our Service. 4.2. Preference Cookies: We use Preference
        Cookies to remember your preferences and various settings. 4.3. Security
        Cookies: We use Security Cookies for security purposes. 4.4. Advertising
        Cookies: Advertising Cookies are used to serve you with advertisements
        that may be relevant to you and your interests.
        <h4>Other Data</h4>
        While using our Service, we may also collect the following information:
        sex, age, date of birth, place of birth, passport details, citizenship,
        registration at place of residence and actual address, telephone number
        (work, mobile), details of documents on education, qualification,
        professional training, employment agreements, non-disclosure agreements,
        information on bonuses and compensation, information on marital status,
        family members, social security (or other taxpayer identification)
        number, office location and other data.
        <h3 style={{ marginBlock: "10px" }}>5. Use of Data</h3>
        <div>
          Linx Arabia uses the collected data for various purposes:
        </div>{" "}
        <div>0.1. to provide and maintain our Service</div>{" "}
        <div>0.2. to notify you about changes to our Service</div>{" "}
        <div>
          0.3. to allow you to participate in interactive features of our
          Service when you choose to do so
        </div>
        <div> 0.4. to provide customer support</div>
        <div>
          {" "}
          0.5. to gather analysis or valuable information so that we can improve
          our Service
        </div>{" "}
        <div>
          0.6. to monitor the usage of our Service; 0.7. to detect, prevent and
          address technical issues
        </div>
        <div> 0.8. to fulfill any other purpose for which you provide it</div>
        <div>
          {" "}
          0.9. to carry out our obligations and enforce our rights arising from
          any contracts entered into between you and us, including for billing
          and collection
        </div>{" "}
        <div>
          0.10. to provide you with notices about your account and/or
          subscription, including expiration and renewal notices,
          email-instructions, etc.
        </div>{" "}
        <div>
          0.11. to provide you with news, special offers and general information
          about other goods, services and events which we offer that are similar
          to those that you have already purchased or enquired about unless you
          have opted not to receive such information
        </div>{" "}
        <div>
          0.12. in any other way we may describe when you provide the
          information
        </div>{" "}
        0.13. for any other purpose with your consent.
        <h3 style={{ marginBlock: "10px" }}>6. Retention of Data</h3>
        We will retain your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies. We will also retain Usage Data for internal
        analysis purposes. Usage Data is generally retained for a shorter
        period, except when this data is used to strengthen the security or to
        improve the functionality of our Service, or we are legally obligated to
        retain this data for longer time periods.
        <h3 style={{ marginBlock: "10px" }}>7. Transfer of Data</h3>
        Your information, including Personal Data, may be transferred to – and
        maintained on – computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction. If you are located
        outside United States, Puerto Rico and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        United States, Puerto Rico and process it there. Your consent to this
        Privacy Policy followed by your submission of such information
        represents your agreement to that transfer. Linx Arabia will take all
        the steps reasonably necessary to ensure that your data is treated
        securely and in accordance with this Privacy Policy and no transfer of
        your Personal Data will take place to an organization or a country
        unless there are adequate controls in place including the security of
        your data and other personal information.
        <h3 style={{ marginBlock: "10px" }}>8. Disclosure of Data</h3>
        We may disclose personal information that we collect, or you provide:
        0.1. Disclosure for Law Enforcement. Under certain circumstances, we may
        be required to disclose your Personal Data if required to do so by law
        or in response to valid requests by public authorities. 0.2. Business
        Transaction. If we or our subsidiaries are involved in a merger,
        acquisition or asset sale, your Personal Data may be transferred. 0.3.
        Other cases. We may disclose your information also: 0.3.1. to our
        subsidiaries and affiliates; 0.3.2. to contractors, service providers,
        and other third parties we use to support our business; 0.3.3. to
        fulfill the purpose for which you provide it; 0.3.4. for the purpose of
        including your company’s logo on our website; 0.3.5. for any other
        purpose disclosed by us when you provide the information; 0.3.6. with
        your consent in any other cases; 0.3.7. if we believe disclosure is
        necessary or appropriate to protect the rights, property, or safety of
        the Company, our customers, or others.
        <h3 style={{ marginBlock: "10px" }}>9. Security of Data</h3>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
        <h3 style={{ marginBlock: "10px" }}>10. Service Providers</h3>
        We may employ third-party companies and individuals to facilitate our
        Service (“Service Providers”), provide Service on our behalf, perform
        Service-related services or assist us in analyzing how our Service is
        used. These third parties have access to your Personal Data only to
        perform these tasks on our behalf and are obligated not to disclose or
        use it for any other purpose.
        <h3 style={{ marginBlock: "10px" }}>11. Analytics</h3>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
        <h3 style={{ marginBlock: "10px" }}>12. CI/CD tools</h3>
        We may use third-party Service Providers to automate the development
        process of our Service.
        <h3 style={{ marginBlock: "10px" }}>13. Advertising</h3>
        We may use third-party Service Providers to show advertisements to you
        to help support and maintain our Service.
        <h3 style={{ marginBlock: "10px" }}>14. Behavioral Remarketing</h3>
        We may use remarketing services to advertise on third party websites to
        you after you visited our Service. We and our third-party vendors use
        cookies to inform, optimize and serve ads based on your past visits to
        our Service.
        <h3 style={{ marginBlock: "10px" }}>15. Payments</h3>
        We may provide paid products and/or services within Service. In that
        case, we use third-party services for payment processing (e.g. payment
        processors). We will not store or collect your payment card details.
        That information is provided directly to our third-party payment
        processors whose use of your personal information is governed by their
        Privacy Policy. These payment processors adhere to the standards set by
        PCI-DSS as managed by the PCI Security Standards Council, which is a
        joint effort of brands like Visa, Mastercard, American Express and
        Discover. PCI-DSS requirements help ensure the secure handling of
        payment information.
        <h3 style={{ marginBlock: "10px" }}>16. Links to Other Sites</h3>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site. We strongly advise you to review the Privacy Policy of
        every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services. For example, the outlined Privacy Policy
        has been created using PolicyMaker.io, a free web application for
        generating high-quality legal documents. PolicyMaker's online privacy
        policy generator is an easy-to-use free tool for creating an excellent
        privacy policy template for a website, blog, online store or app.
        <h3 style={{ marginBlock: "10px" }}>17. Children's Privacy</h3>
        Our Services are not intended for use by children under the age of 18
        (“Child” or “Children”). We do not knowingly collect personally
        identifiable information from Children under 18. If you become aware
        that a Child has provided us with Personal Data, please contact us. If
        we become aware that we have collected Personal Data from Children
        without verification of parental consent, we take steps to remove that
        information from our servers.
        <h3 style={{ marginBlock: "10px" }}>
          18. Changes to This Privacy Policy
        </h3>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We will
        let you know via email and/or a prominent notice on our Service, prior
        to the change becoming effective and update “January 2024” at the top of
        this Privacy Policy. You are advised to review this Privacy Policy
        periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
        <h3 style={{ marginBlock: "10px" }}>19. Contact Us</h3>
        If you have any questions about this Privacy Policy, please contact us
        by email: <b>info@linxarabia.com</b>
      </div>
    </>
  );
}

export default Content;
