import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Content from "./Content";
import EmailSubscribe from "../EmailSubscribe";
import BackToTop from "../BackToTop";
import Breadcrumbs from "../Breadcrumbs";
import BreadcrumbsData from "../breadcrumbs.json";

const OurClients = () => {
  return (
    <>
      <Header />
      <Breadcrumbs
        title={BreadcrumbsData.ourclient.title}
        description={BreadcrumbsData.ourclient.description}
      />
      <Content />
      {/* <EmailSubscribe /> */}
      <Footer />
      {/* <SearchPopup />
      <RequestModal /> */}
      <BackToTop />
    </>
  );
};
export default OurClients;
