import React, { useEffect } from "react";
import MainNavigation from "./components/MainNavigation";
import "./i18n.js";
import { useTranslation } from "react-i18next";
import numbro from "numbro";

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const scriptmasonary = document.createElement("script");
    scriptmasonary.src = "/js/masonary-custom.js";
    scriptmasonary.async = true;
    document.head.appendChild(scriptmasonary);
  });

  useEffect(() => {
    const selectedLang = localStorage.getItem("i18nextLng") || "en";
    i18n.changeLanguage(selectedLang);

    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
  }, []);

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;

    const locale = "arabic-numeric";
    numbro.setLanguage(locale);
  }, [i18n, i18n.language]);

  return <MainNavigation />;
};

export default App;
