import React from "react";
import { useTranslation } from "react-i18next";

const Content = () => {
  const { t } = useTranslation();

  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100">
          <div className="container">
            <div className="contact-map-bg">
              <img src="images/map-bg.png" alt="" />
            </div>
            <div className="row justify-content-center">
              <div
                dir="ltr"
                className="col-md-6 col-sm-12 col-lg-4 wow fadeInRight"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <div className="contact-detail-shadow">
                  <h4>{t("saudiArabia")}</h4>
                  <div className="d-flex align-items-start items">
                    <i className="icofont-google-map"></i>{" "}
                    <span>{t("address")}</span>
                  </div>
                  <div className="d-flex align-items-start items">
                    <i className="icofont-phone"></i>{" "}
                    <span dir="ltr">+966 50 9651 493 <br /> +966 12 4222026</span>
                  </div>
                  <div className="text-nowrap d-flex align-items-start items">
                    <i className="icofont-email"></i>{" "}
                    <a href="#">info@linxarabia.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="wide-tb-100 bg-light-gray pt-0">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 col-sm-12 col-lg-8 offset-lg-2 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <div className="free-quote-form contact-page">
                  <h1 className="heading-main mb-4">Get in touch</h1>
                  <form
                    action="#"
                    method="post"
                    id="contactusForm"
                    noValidate="novalidate"
                    className="col rounded-field"
                  >
                    <div className="form-row mb-4">
                      <div className="col">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="form-row mb-4">
                      <div className="col">
                        <select
                          title="Please choose a package"
                          required=""
                          name="Transport_Package"
                          id="Transport_Package"
                          className="custom-select"
                          aria-required="true"
                          aria-invalid="false"
                        >
                          <option value="">Transport Type</option>
                          <option value="Type 1">Type 1</option>
                          <option value="Type 2">Type 2</option>
                          <option value="Type 3">Type 3</option>
                          <option value="Type 4">Type 4</option>
                        </select>
                      </div>
                      <div className="col">
                        <select
                          title="Please choose a package"
                          required=""
                          name="Freight_Package"
                          id="Freight_Package"
                          className="custom-select"
                          aria-required="true"
                          aria-invalid="false"
                        >
                          <option value="">Type of freight</option>
                          <option value="Type 1">Type 1</option>
                          <option value="Type 2">Type 2</option>
                          <option value="Type 3">Type 3</option>
                          <option value="Type 4">Type 4</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-row mb-4">
                      <div className="col">
                        <textarea
                          rows="7"
                          name="cment"
                          id="cment"
                          placeholder="Message"
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-row text-center">
                      <button
                        name="contactForm"
                        id="contactForm"
                        type="button"
                        className="form-btn mx-auto btn-theme bg-orange"
                      >
                        Submit Now <i className="icofont-rounded-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="map-bg">
          <div id="map-holder" className="pos-rel width-100">
            <div id="map_extended">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3711.752386774464!2d39.165472199999996!3d21.517416700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDMxJzAyLjciTiAzOcKwMDknNTUuNyJF!5e0!3m2!1sen!2s!4v1691756511537!5m2!1sen!2s"
                width="100%"
                height="500"
                // style="border:0;"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
