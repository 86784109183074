import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Content from "./Content";
import Breadcrumbs from "../Breadcrumbs";
import EmailSubscribe from "../EmailSubscribe";
import BackToTop from "../BackToTop";
import SearchPopup from "../SearchPopup";
import VideoBox from "./VideoBox";
import RequestModal from "../RequestModal";
import BreadcrumbsData from "../breadcrumbs.json";

const About = () => {
  return (
    <>
      <Header />
      <Content />
      {/* <EmailSubscribe /> */}
      <Footer />
      <VideoBox />
      <SearchPopup />
      <RequestModal />
      <BackToTop />
    </>
  );
};
export default About;
