import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Content from "./Content";
import Breadcrumbs from "../../Breadcrumbs";
import EmailSubscribe from "../../EmailSubscribe";
import BackToTop from "../../BackToTop";
import SearchPopup from "../../SearchPopup";
import RequestModal from "../../RequestModal";
import BreadcrumbsData from "../../breadcrumbs.json";

const ContactusOption = () => {
  return (
    <>
      <Header />
      {/* <Breadcrumbs title={BreadcrumbsData.contact.title} description={BreadcrumbsData.contact.description}/> */}
      <Content />
      <EmailSubscribe />
      <Footer />
      <SearchPopup />
      <RequestModal />
      <BackToTop />
    </>
  );
};
export default ContactusOption;
