import React from "react";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <Loader /> */}
      <header className="fixed-top header-fullpage top-border top-transparent wow fadeInDown">
        <nav className="navbar navbar-expand-lg bg-transparent">
          <div className="container text-nowrap bdr-nav px-0">
            <div className="d-flex mr-auto">
              <a className="navbar-brand rounded-bottom light-bg" href="/">
                <img src="images/logo_white.png" alt="" />
              </a>
            </div>
            {/* <span className="order-lg-last d-inline-flex request-btn">
              <a className="nav-link mr-2 ml-auto" href="#" id="search_home">
                <i className="icofont-search"></i>
              </a>
            </span> */}
            <button
              className="navbar-toggler x collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarCollapse"
              data-hover="dropdown"
              data-animations="slideInUp slideInUp slideInUp slideInUp"
            >
              <ul
                className={`navbar-nav ${
                  i18n.language === "ar" ? "mr" : "ml"
                }-auto`}
              >
              
                <li className="nav-item">
                  <a className="nav-link" href="about">
                    {t("aboutUs")}
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle-mob"
                    href="home-one"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Pages <i className="icofont-rounded-down"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="shortcodes">
                        Shortcode Elements
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="typography">
                        Typography
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="requestquote">
                        Request Quote
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="pricing">
                        Our Pricing
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="notfound">
                        404 Page
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="services">
                    {t("services")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="ourclients">
                    {t("ourClients")}
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle-mob"
                    href="home-one"
                    id="blog-menu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Blog <i className="icofont-rounded-down"></i>
                  </a>

                  <ul className="dropdown-menu" aria-labelledby="blog-menu">
                    <li>
                      <a className="dropdown-item" href="bloglist">
                        Blog List
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="blogstandard">
                        Blog Standard
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="bloggrid">
                        Blog Grid
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="blogsingle">
                        Blog Single
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="contact">
                    {t("contactUs")}
                  </a>
                  {/* <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="contact">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="contactusoption">
                        Contact Us Option
                      </a>
                    </li>
                  </ul> */}
                </li>
                <li className="nav-item">
                  <div className="col-sm-12 text-center">
                    <a
                      href="https://booking.linxarabia.com/#/page/register"
                      target="_blank"
                      className="btn-theme bg-orange"
                    >
                      {t("requestQuote")}{" "}
                      <i
                        className={`icofont-rounded-${
                          i18n.language === "en" || i18n.language === "en-US"
                            ? "right"
                            : "left"
                        }`}
                      ></i>
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={() => {
                      i18n.language === "en" || i18n.language === "en-US"
                        ? i18n.changeLanguage("ar")
                        : i18n.changeLanguage("en");

                      window.location.reload();
                    }}
                  >
                    {t("changeLanguage", {
                      language:
                        i18n.language === "en" || i18n.language === "en-US"
                          ? "العربية"
                          : "English",
                    })}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
