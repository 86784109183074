import React from "react";

const Content = () => {
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100 map-bg">
          <div id="map-holder" className="pos-rel width-100">
            <div id="map_extended">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3711.752386774464!2d39.165472199999996!3d21.517416700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDMxJzAyLjciTiAzOcKwMDknNTUuNyJF!5e0!3m2!1sen!2s!4v1691756511537!5m2!1sen!2s"
                width="100%"
                height="500"
                // style="border:0;"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="wide-tb-100 pos-rel">
          <div className="container">
            <div className="contact-map-bg option">
              <img src="images/map-bg.png" alt="" />
            </div>
            <div className="row">
              <div className="col-md-4">
                <h2 className="h2-md mb-4 fw-7 txt-blue">Our Address</h2>
                <div
                  className="contact-detail-shadow no-shadow mb-5 wow fadeInRight"
                  data-wow-duration="0"
                  data-wow-delay="0s"
                >
                  <h4>Saudi Arabia</h4>
                  <div className="d-flex align-items-start items">
                    <i className="icofont-google-map"></i>{" "}
                    <span>
                      Tihama Building, Andalus Road, Al Hamra, Jeddah, Saudi
                      Arabia 
                    </span>
                  </div>
                  <div className="d-flex align-items-start items">
                    <i className="icofont-phone"></i>{" "}
                    <span>+966-509651493</span>
                  </div>
                  <div className="text-nowrap d-flex align-items-start items">
                    <i className="icofont-email"></i>{" "}
                    <a href="#">info@linxarabia.com</a>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
                <h2 className="h2-md mb-4 fw-7 txt-blue">
                  Say Hello! Its Free
                </h2>
                <div className="">
                  <div
                    className="free-quote-form contact-page-option wow fadeInLeft"
                    data-wow-duration="0"
                    data-wow-delay="0s"
                  >
                    <form
                      action="#"
                      method="post"
                      id="contactoption"
                      noValidate="novalidate"
                      className="rounded-field"
                    >
                      <div className="form-row mb-4">
                        <div className="col">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Your Name"
                          />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="form-row mb-4">
                        <div className="col">
                          <select
                            title="Please choose a package"
                            required=""
                            name="Transport_Package"
                            id="Transport_Package"
                            className="custom-select"
                            aria-required="true"
                            aria-invalid="false"
                          >
                            <option value="">Transport Type</option>
                            <option value="Type 1">Type 1</option>
                            <option value="Type 2">Type 2</option>
                            <option value="Type 3">Type 3</option>
                            <option value="Type 4">Type 4</option>
                          </select>
                        </div>
                        <div className="col">
                          <select
                            title="Please choose a package"
                            required=""
                            name="Freight_Package"
                            id="Freight_Package"
                            className="custom-select"
                            aria-required="true"
                            aria-invalid="false"
                          >
                            <option value="">Type of freight</option>
                            <option value="Type 1">Type 1</option>
                            <option value="Type 2">Type 2</option>
                            <option value="Type 3">Type 3</option>
                            <option value="Type 4">Type 4</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-row mb-4">
                        <div className="col">
                          <textarea
                            name="cment"
                            rows="7"
                            placeholder="Message"
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-row text-center">
                        <button
                          name="contactoption"
                          id="contactoption"
                          type="button"
                          className="form-btn mx-auto btn-theme bg-orange"
                        >
                          Submit Now <i className="icofont-rounded-right"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
