import React from "react";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <main id="body-content">
        {/* <section className="wide-tb-80 bg-fixed what-we-offer">
                    <div className="container pos-rel">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mb-4 fw-7 txt-blue wow fadeInLeft" data-wow-duration="0" data-wow-delay="0s">
                                    Quality <span className="fw-6 txt-orange">and</span> Performance <br /><span className="fw-6 txt-orange">at the right price</span>
                                </h2>
                                <p className="wow fadeInLeft" data-wow-duration="0" data-wow-delay="0.2s">Energistically utilize team driven niche markets rather than leveraged platforms. Monotonectally restore tactical "outside the box" thinking and technically sound deliverables. </p>
                                <p className="wow fadeInLeft" data-wow-duration="0" data-wow-delay="0.4s">Compellingly develop fully researched process improvements through innovative opportunities. Credibly productize highly efficient potentialities for vertical core competencies. Quickly maintain pandemic experiences rather than low-risk high-yield processes.</p>
                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="bg-white wide-tb-100">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>{t("whatWeOffer")}</span>
                  {t("ourMainServices")}
                </h1>
              </div>
              <div
                className="col-md-4 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <a href="service-details.html">
                  <div className="icon-box-1">
                    <img src="images/icon-box-1.jpg" alt="" />
                    <div className="text">
                      <i className="icofont-vehicle-delivery-van"></i>
                      {t("groundDelivery")}
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.4s"
              >
                <a href="service-details.html">
                  <div className="icon-box-1">
                    <img src="images/icon-box-2.jpg" alt="" />
                    <div className="text">
                      <i className="icofont-airplane-alt"></i>
                      {t("airDelivery")}
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4 wow fadeInUp"
                data-wow-duration="0"
                data-wow-delay="0.6s"
              >
                <a href="service-details.html">
                  <div className="icon-box-1">
                    <img src="images/icon-box-3.jpg" alt="" />
                    <div className="text">
                      <i className="icofont-ship"></i>
                      {t("seaDelivery")}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-sky-blue wide-tb-100">
          <div className="container pos-rel">
            <div className="row">
              <div className="img-business-man">
                <img src="images/courier-man.png" alt="" />
              </div>
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>{t("ourGoodness")}</span>
                  {t("whatMakesUsSpecial")}
                </h1>
              </div>
              <div className="col-md-6 ml-auto">
                <div className="row">
                  <div
                    className="col-12 wow fadeInUp"
                    data-wow-duration="0"
                    data-wow-delay="0s"
                  >
                    <div className="icon-box-3 mb-5 bg-sky-blue">
                      <div className="media">
                        <div className="service-icon mr-5">
                          <i className="icofont-box"></i>
                        </div>
                        <div
                          style={{
                            marginRight:
                              i18n.dir() === "rtl" ? "50px" : "initial",
                          }}
                          className="service-inner-content media-body"
                        >
                          <h4
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                            className="h4-md"
                          >
                            {t("packagingAndStorage")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("packagingAndStorageDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 wow fadeInUp"
                    data-wow-duration="0"
                    data-wow-delay="0.2s"
                  >
                    <div className="icon-box-3 mb-5 bg-sky-blue">
                      <div className="media">
                        <div className="service-icon mr-5">
                          <i className="icofont-shield"></i>
                        </div>
                        <div
                          style={{
                            marginRight:
                              i18n.dir() === "rtl" ? "50px" : "initial",
                          }}
                          className="service-inner-content media-body"
                        >
                          <h4
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                            className="h4-md"
                          >
                            {t("safetyAndQuality")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("safetyAndQualityDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 wow fadeInUp"
                    data-wow-duration="0"
                    data-wow-delay="0.4s"
                  >
                    <div className="icon-box-3 bg-sky-blue">
                      <div className="media">
                        <div className="service-icon mr-5">
                          <i className="icofont-tree-alt"></i>
                        </div>
                        <div
                          className="service-inner-content media-body"
                          style={{
                            marginRight:
                              i18n.dir() === "rtl" ? "50px" : "initial",
                          }}
                        >
                          <h4
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                            className="h4-md"
                          >
                            {t("careForEnvironment")}
                          </h4>
                          <p
                            style={{
                              textAlign:
                                i18n.dir() === "ltr" ? "left" : "right",
                            }}
                          >
                            {t("careForEnvironmentDesc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wide-tb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 ml-lg-auto pos-rel col-md-12">
                <h1 className="heading-main text-left">
                  <span>get updates</span>
                  Tracking Your Freight
                </h1>
                <form className="form-inline tracking">
                  <input
                    type="text"
                    className="form-control mb-2 mr-sm-2 col"
                    placeholder="Enter order number"
                  />
                  <button
                    type="submit"
                    className="btn btn-theme bg-orange mb-2 ml-3"
                  >
                    Check Now <i className="icofont-rounded-right"></i>
                  </button>
                </form>
                <div
                  className="forklift-image wow slideInLeft"
                  data-wow-duration="0"
                  data-wow-delay="0s"
                >
                  <img src="images/forklift_Image.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="bg-light-gray">
          <div className="container-fluid p-0">
            <div className="row align-items-center no-gutters">
              <div className="col-lg-4 text-center">
                <div className="px-5 wide-tb-100">
                  <div className="service-icon mx-auto mb-5 icon-box-5">
                    <i className="icofont-glass"></i>
                  </div>
                  <h4 className="h4-md fw-7 txt-blue">{t("safeAndSecure")}</h4>
                  {t("safeAndSecureDesc")}
                </div>
              </div>
              <div className="col-lg-4 text-center bg-fixed clients-bg pos-rel txt-white">
                <div className="bg-overlay black opacity-40"></div>
                <div
                  className="px-5 wide-tb-100"
                  style={{ position: "relative", zIndex: "999" }}
                >
                  <div className="service-icon mx-auto mb-5 icon-box-5">
                    <i className="icofont-delivery-time"></i>
                  </div>
                  <h4 className="h4-md fw-7">{t("fastDelivery")}</h4>
                  {t("fastDeliveryDesc")}
                </div>
              </div>
              <div className="col-lg-4 text-center">
                <div className="px-5 wide-tb-100">
                  <div className="service-icon mx-auto mb-5 icon-box-5">
                    <i className="icofont-live-support"></i>
                  </div>
                  <h4 className="h4-md fw-7 txt-blue"> {t("247Support")} </h4>
                  {t("247SupportDesc")}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="wide-tb-100">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>What Our</span>
                  Customers Saying
                </h1>
              </div>
              <div className="col-sm-12">
                <div
                  className="owl-carousel owl-theme"
                  id="home-client-testimonials"
                >
                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                          <img src="images/team_1.jpg" alt="" />
                        </div>
                        <div className="client-inner-content media-body">
                          <p>
                            Far far away, behind the word mountains, far from
                            the countries Vokalia and Consonantia, there live
                            the blind texts. Aliquam gravida, urna quis ornare
                            imperdiet,{" "}
                          </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">
                              John Gerry Design Hunt
                            </cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                          <img src="images/team_2.jpg" alt="" />
                        </div>
                        <div className="client-inner-content media-body">
                          <p>
                            Far far away, behind the word mountains, far from
                            the countries Vokalia and Consonantia, there live
                            the blind texts. Aliquam gravida, urna quis ornare
                            imperdiet,{" "}
                          </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">
                              John Gerry Design Hunt
                            </cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                          <img src="images/team_3.jpg" alt="" />
                        </div>
                        <div className="client-inner-content media-body">
                          <p>
                            Far far away, behind the word mountains, far from
                            the countries Vokalia and Consonantia, there live
                            the blind texts. Aliquam gravida, urna quis ornare
                            imperdiet,{" "}
                          </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">
                              John Gerry Design Hunt
                            </cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
       <section className="wide-tb-100 bg-white pos-rel" dir="ltr">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 wow fadeInDown"
                data-wow-duration="0"
                data-wow-delay="0s"
              >
                <h1 className="heading-main">
                  <span>{t("someOfOur")}</span>
                  {t("clients")}
                </h1>
              </div>
              <div
                className="col-sm-12 wow slideInDown"
                data-wow-duration="0"
                data-wow-delay="0.1s"
              >
                <div
                  className="owl-carousel owl-theme align-items-center justify-content-center"
                  id="home-clients"
                >
                
                  <div className="item">
                    <img src="images/clients/client4.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client5.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client6.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client7.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client8.png" alt="" />
                  </div>
                  <div className="item ">
                    <img src="images/clients/client9.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client10.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client11.png" alt="" />
                  </div>
                  <div className="item">
                    <img src="images/clients/client12.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="wide-tb-80 bg-scroll bg-img-6 pos-rel callout-style-1">
          <div className="bg-overlay blue opacity-60"></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-12 mb-0">
                <h4 className="h4-xl">Interested in working with Logzee?</h4>
              </div>
              <div className="col">
                <div className="center-text">
                  We don’t just manage suppliers, we micro-manage them. We have
                  a consultative, personalized approach
                </div>
              </div>
              <div className="col-sm-auto">
                <a href="#" className="btn btn-theme bg-white bordered">
                  Get In Touch <i className="icofont-rounded-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </>
  );
};
export default Services;
